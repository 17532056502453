<template src="@/../../../templates/brand/components/templates/shop/OrderDetails.html"> </template>

<script>
import _ from 'lodash/fp'
import { mapActions, mapState, mapGetters } from 'vuex'
import accounting from 'accounting'
import Lightbox from '@/components/Lightbox.vue'
import CreditProfiles from '@/components/CreditProfiles.vue'
import { shippingMap } from '@/util/util'

// logic
export default {
  name: 'OrderDetail',
  created () {
    this.$http
      .get(`/orders/${this.$route.params.orderid}?populate=true`)
      .then(res => {
        this.order = res.data.data
        this.order.items = _.sortBy(['product.SKU'], this.order.items)
        if (this.order.parent) {
          for (const _order of this.order.orders) {
            _order.items = _.sortBy(['product.SKU'], _order.items)
          }
        }
        if (this.order.type !== 'credit') {
          this.shippingService = shippingMap(
            this.order.shippingService.rawdata,
            this.appconfig.siteConfig.shippingMap[this.order.shippingService.carrier]
          )
        }
      })
  },
  components: { Lightbox, CreditProfiles },
  data () {
    return {
      lightboxVisible: false,
      lbitem: null,
      locales: _.keys(this.$store.state.lang),
      order: {
        price: {},
        paymentProfile: { ResDataMaskedPan: '' },
        shipping: { address: {} },
        billing: { address: {} },
        shippingService: {}
      },
      shippingService: {},
      showProfiles: false
    }
  },
  computed: {
    ...mapState({
      user: ({ App }) => App.user
    }),
    ...mapGetters({
      appconfig: 'const'
    }),
    lburl () {
      if (!this.lbitem) return false
      return `/assets/user/${this.user._id}/${this.order._id}/${this.lbitem}.jpg`
    },
    serviceDescription () {
      return this.appconfig.locale[this.$i18n.locale].upsServiceTypeMap[
        this.order.shippingService.serviceCode
      ]
    }
  },
  // component methods
  methods: {
    ...mapActions(['changeOrderCard']),
    showPreview (item) {
      this.lbitem = `${item.product.familyKey}${item.product.variationKey ||
        ''}`
      this.lightboxVisible = true
      this.$emit('lightbox.show')
      // orderApi.get({url: orderApi.url + '/' + this.order._id + '/makepreview'});
    },
    orderStatus () {
      if (!this.order.shipDate) return 'in progress'
      if (!this.order.receivedDate) return 'en route'
      if (this.order.receivedDate && !this.order.paymentReceived)
        return 'payment outstanding'
      return 'complete'
    },
    // // NB: temporary fix for order.items not binding adjusted values to unit and total price
    // unitPrice (item) {
    //   if (item.product.adjustments.length && item.product.adjustments.some(a => a.volume)) {
    //     const _adj = item.product.adjustments.sort((a, b) => a.volume < b.volume)
    //     const _price = _adj.find(a => a.volume <= item.quantity)
    //     return _price ? _price.value : item.product.price
    //   } else {
    //     return item.product.price
    //   }
    // },
    // totalPrice (item) {
    //   return this.unitPrice(item) * item.quantity
    // },
    // // \/
    productName (product) {
      const _prod = this.order.products.find(i => i.SKU === product.SKU)
      return _prod.content[this.$i18n.locale].name
    },
    productImage (product) {
      const _prod = this.order.products.find(i => i.SKU === product.SKU)
      return _prod.images.find(i => i.default) || _prod.images[0]
    },
    customLogos (item) {
      return _.keys(item.customization)
    },
    currencyLocale (val) {
      return accounting.formatMoney(
        val,
        this.appconfig.locale[this.$i18n.locale].currency
      )
    },
    // serviceDescription (service) {
    //   return this.appconfig.locale[this.$i18n.locale].upsServiceTypeMap[service]
    // },
    changeCC (type, id) {
      if (type === 'select') {
        this.changeOrderCard({ order: this.order, profile: id })
      }
    }
  },
  // event listeners
  events: {
    'lightbox.close': function () {
      this.lightboxVisible = false
    }
  }
}
</script>

<style src="@/../../../templates/brand/components/styles/shop/OrderDetails.css"></style>
